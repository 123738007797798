import { Show } from "solid-js";

import InputField from "~/components/input/InputField";
import { login } from "~/api";
import * as Card from "~/components/ui/card";
import { A, action, redirect, useAction, useLocation, useSubmission } from "@solidjs/router";

const env = import.meta.env.VITE_ENV;

const googleSignIn_ = action(async (search: string) => {
  try {
    const response = await fetch(`/api/google-login${search}`);
    const data = await response.json();
    return redirect(data.data.url);
  } catch (error) {
    console.error(error);
    throw error;
  }
});

export default function Login() {
  const googleSignIn = useAction(googleSignIn_);
  const loggingIn = useSubmission(login);
  const location = useLocation();

  return (
    <div class="h-full mx-auto flex items-center justify-between max-w-sm md:max-w-3xl lg:max-w-5xl">
      <Card.Root>
        <Card.Body class="pt-6">
          <h2 class="text-start text-2xl mb-3">Sign in to our platform</h2>
          <Show when={env === "development"}>
            <>
              <form action={login} method="post" class="w-80">
                <input type="hidden" name="search" value={location.search} />
                <div class="flex flex-col space-y-4 mb-4">
                  <InputField name="email" label="Your email" />
                  <InputField name="password" type="password" label="Password" />
                  <Show when={loggingIn.result instanceof Error}>
                    <span class="text-red-500 text-xs lg:text-sm">Invalid login credentials. Please try again.</span>
                  </Show>
                </div>
                <button
                  class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 disabled:cursor-not-allowed"
                  type="submit"
                  disabled={loggingIn.pending}
                >
                  <Show when={loggingIn.pending} fallback="Log in">
                    <div class="mx-auto h-5 w-5 animate-spin rounded-full border-b-2 border-white" />
                  </Show>
                </button>
                <div class="text-sm text-start">
                  Don't have an accoount?{" "}
                  <A class="text-blue-700" href="/auth/register">
                    Create an account
                  </A>
                </div>
              </form>
              <div class="my-2">Or</div>
            </>
          </Show>
          <button
            onClick={() => googleSignIn(location.search)}
            class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 disabled:cursor-not-allowed"
            disabled={loggingIn.pending}
          >
            Sign in with Google
          </button>
        </Card.Body>
      </Card.Root>
    </div>
  );
}
