import { Show } from "solid-js";
import { FormLabel } from "../ui/form-label";
import { Input } from "../ui/input";
import { camelCase } from "change-case";
import { Textarea } from "../ui/textarea";

export default function InputField({
  name,
  label,
  type = "text",
  required = true,
  min,
  max,
  placeholder,
}: {
  name: string;
  label: string;
  type?: string;
  required?: boolean;
  min?: string;
  max?: string;
  placeholder?: string;
}) {
  return (
    <div class="relative flex flex-col items-start justify-start gap-2">
      <FormLabel
        for={name}
        class="block text-sm font-semibold text-gray-900 dark:text-white"
      >
        {label}
      </FormLabel>
      <Show
        when={type === "textarea"}
        fallback={
          <Input
            type={type}
            id={name}
            name={name}
            required={required}
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder={placeholder || label}
            min={min}
            max={max}
          />
        }
      >
        <Textarea
          id={name}
          name={name}
          required={required}
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder={placeholder || label}
          rows={4}
        />
      </Show>
    </div>
  );
}
